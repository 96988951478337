/* eslint-disable */
import clsx from 'clsx';
import React, { useContext, useState, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import axios from 'axios';

import { IconButton, Input } from '@mui/material';
import { Button, Modal, TextInput, HelpTooltip } from 'tt-ui-kit';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import BlockIcon from '@mui/icons-material/Block';
import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DropArea from './DropArea';

import { ReactComponent as DropBoxSvgG } from '../../assets/icons/dropBoxIcoGray.svg';
import { ReactComponent as GdriveSvgG } from '../../assets/icons/gdriveIcoGray.svg';
import { ReactComponent as IcloudSvgG } from '../../assets/icons/iCloudIcoGray.svg';
import { ReactComponent as ConfDocSvg } from '../../assets/icons/confDoc.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as GeneratingIco } from '../../assets/icons/generating.svg';
import pageStyles from '../../views/pages.module.scss';

import UserContext from '../../context/User/userContext';
import CalculatorsContext from '../../context/Calculators/calculatorsContext';
import styles from './FileUploader.module.scss';
import TooltipState from '../tooltipState/TooltipState';

import { ErrorContext } from '../../context';
import { acceptedExtentions } from '../../constants';
import {
  getFiles,
  setFiles,
  deleteFiles,
  addFiles,
  getUploadUrl,
  uploadFileToAWS,
} from '../../api/rest/list';

// const NAME_LENGTH_LIMIT = 14;
// const SIZE_LIMIT = 2048000;

const icoStyle = { width: 20, height: 20, flexShrink: 0, fontSize: 20 };

const STATUSES = {
  LOADING: 'LOADING',
  GOT_UPLOAD_LINK: 'GOT_UPLOAD_LINK',
  UPLOADED: 'UPLOADED',
  SAVED: 'SAVED',
  PROCESSING: 'PROCESSING',
  ERROR_UPLOADING: 'ERROR_UPLOADING',
  ERROR_EXTENTION: 'ERROR_EXTENTION',
  ERROR_SIZE: 'ERROR_SIZE',
};

const STATE_TEXT = {
  PROCESSING: 'The files are being processed',
  UPLOADED: 'The files have been uploaded',
  SAVED: 'The files have been saved',
  ERROR_UPLOADING: 'Error uploading the files',
  ERROR_EXTENTION: 'The files have an unsupported extension',
  ERROR_SIZE: 'The files are too large',
};

const validateSize = (file) => file.size < 1e8;

const getExt = (file) => file.name.split('.').pop();

const getFileType = (file, calcName) => {
  const ext = getExt(file);
  const exts = acceptedExtentions[calcName] || acceptedExtentions.COMMON;
  if (exts.acceptedImages && exts.acceptedImages.includes(ext)) return 'image';
  if (exts.acceptedDocs && exts.acceptedDocs.includes(ext)) return 'doc';
  return '';
};

const validateExt = (file, calcName) => !!getFileType(file, calcName);

const getState = (file, calcName) => {
  if (file.error) return STATUSES.ERROR_UPLOADING;
  if (!validateExt(file, calcName)) return STATUSES.ERROR_EXTENTION;
  if (!validateSize(file)) return STATUSES.ERROR_SIZE;
  return STATUSES.PROCESSING;
};

const isValid = (file, calcName) => validateExt(file, calcName) && validateSize(file);

const fileStatusStyle = (status) => {
  if (status === STATUSES.ERROR_EXTENTION) return styles.rejected;
  if (status === STATUSES.ERROR_SIZE) return styles.rejected;
  if (status === STATUSES.ERROR_UPLOADING) return styles.rejected;
  if (status === STATUSES.SAVED) return styles.accepted;
  return styles.loading;
};

const fileBgStatusStyle = (status) => {
  if (status === STATUSES.LOADING) return styles.loading;
  if (status === STATUSES.GOT_UPLOAD_LINK) return styles.uploadLink;
  if (status === STATUSES.UPLOADED) return styles.uploaded;
  if (status === STATUSES.SAVED) return styles.saved;
  return styles.loading;
};

const getFileStatusIcon = (status) => {
  if (status === STATUSES.ERROR_EXTENTION) return <BlockIcon />;
  if (status === STATUSES.ERROR_SIZE) return <BlockIcon />;
  if (status === STATUSES.ERROR_UPLOADING) return <BlockIcon />;
  if (status === STATUSES.PROCESSING) return <GeneratingIco className={pageStyles.rotating} />;
  if (status === STATUSES.SAVED) return <TaskOutlinedIcon />;
  return <RestorePageOutlinedIcon />;
};

const FileUploader = ({
  questionCode,
  disabled,
  btnType,
  btnText,
  title,
  isMultiple = true,
  onCloseCallback = null,
}) => {
  const { reportId } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);
  const { calculatorName } = useContext(CalculatorsContext);

  const [fileToDelete, setFileToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [errorFilesList, setErrorFilesList] = useState([]);

  const [viewDescriptionId, setViewDescriptionId] = useState(null);
  const [idsForSave, setIdsForSave] = useState([]);

  const onClose = () => {
    setIsModalOpen(false);
    if (onCloseCallback) onCloseCallback(filesList);
  };

  const onOpen = () => {
    setIsModalOpen(true);
  };

  const loadFiles = () => {
    getFiles({ object_id: reportId, object_subcode: questionCode })
      .then((res) => {
        const { data, success } = res?.data;
        if (success) {
          const loadesUUids = data.map((f) => f.uuidName);
          setFilesList((current) => [
            ...current.filter((f) => !loadesUUids.includes(f.uuidName)),
            ...data.map((f) => ({
              ...f,
              status: STATUSES.SAVED,
            })),
          ]);
        }
      })
      .catch((err) => {
        setErrorAlert('Error loading files. Please try again later.', err);
      });
  };

  useEffect(() => {
    if (!!questionCode && !!reportId) loadFiles();
    return () => {
      setFilesList([]);
    };
  }, [reportId, questionCode, isModalOpen]);

  const updateFileState = (uuidName, newState) => {
    setFilesList((prevState) => prevState.map((file) =>
      file.uuidName === uuidName ? { ...file, status: newState } : file
    ));
  };

  const updateFileId = (uuidName, id) => {
    setFilesList((prevState) => prevState.map((file) =>
      file.uuidName === uuidName ? { ...file, id, status: STATUSES.SAVED } : file
    ));
  };

  const uploadFile = async (file) => {
    try {
      const respUrl = await getUploadUrl({ name: file.uuidName, size: `${file.f.size}` });
      const { data: url, success } = respUrl?.data ?? {};
      if (success && !url) throw new Error('No URL to upload file');
      updateFileState(file.uuidName, STATUSES.GOT_UPLOAD_LINK);

      await uploadFileToAWS(url, file.f);
      updateFileState(file.uuidName, STATUSES.UPLOADED);

      const resSave = await addFiles({
        object_subcode: questionCode,
        object_id: reportId,
        type: file.type,
        original_name: file.originalName,
        file: file.uuidName,
        size: `${file.f.size}`,
        description: '',
      });

      const { data, success: saveSuccess } = resSave?.data ?? {};
      if (!saveSuccess) throw new Error('Error saving file information');
      updateFileId(file.uuidName, data.id);

      return data;
    } catch (error) {
      setErrorAlert('Error uploading the file', error);
      updateFileState(file.uuidName, STATUSES.ERROR_UPLOADING);
      throw error;
    }
  };

  const saveQuestionFiles = async () => {
    const filePromises = filesToUpload.map(file => {
      // Update initial state
      updateFileState(file.uuidName, STATUSES.LOADING);
      return uploadFile(file);
    });

    try {
      await Promise.all(filePromises);
      // Clear upload array
      setFilesToUpload([]);
    } catch (error) {
      setErrorAlert('Error uploading files', error);
    }
  };

  const closeDeleteModal = () => {
    setFileToDelete(null);
  };

  const deleteQuestionsFile = () => {
    deleteFiles({ id: fileToDelete.id })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const actualFilesList = filesList.filter(
            (file) => file.id !== data.id && data.isDeleted
          );
          setFilesList(actualFilesList);
        }
      })
      .catch((err) => {
        setErrorAlert('Error deleting file. Please try again later.', err);
      })
      .finally(() => closeDeleteModal());
  };

  const updateFilesList = (fList) => {
    const newList = [];
    const wrongList = [];
    for (let i = 0; i < fList.length; i += 1) {
      const newFile = {
        f: fList[i],
        originalName: fList[i].name,
        uuidName: `${uuidv1()}.${getExt(fList[i])}`,
        status: getState(fList[i], calculatorName),
        type: getFileType(fList[i], calculatorName),
      };
      if (!isValid(fList[i], calculatorName)) wrongList.push(newFile);
      else newList.push(newFile);
    }
    setErrorFilesList((current) => [...current, ...wrongList]);
    setFilesList((current) => [...current, ...newList]);
    setFilesToUpload((current) => [...current, ...newList]);
  };

  useEffect(() => {
    if (!filesToUpload.length) return;
    saveQuestionFiles();
  }, [filesToUpload]);


  const removeFileFromList = (file) => {
    if (file?.id) {
      setFileToDelete(file);
    } else {
      const actualFilesList = filesList.filter((f) => f.uuidName !== file.uuidName);
      setFilesList(actualFilesList);
    }
  };

  const onInputChange = (e) => {
    updateFilesList(e.target.files);
  };

  const onExpandClick = (id) => setViewDescriptionId((value) => (value === id ? null : id));

  const onSaveFile = () => {
    const filesInput = idsForSave.map((uuidName) => ({
      id: filesList.find((f) => f.uuidName === uuidName)?.id,
      description: filesList.find((f) => f.uuidName === uuidName)?.description,
    }));
    setFiles({ files: filesInput }).then((res) => setIdsForSave([]));
  };

  const onEditDescription = (file, newDescription) => {
    if (idsForSave.indexOf(file.uuidName) === -1) setIdsForSave([...idsForSave, file.uuidName]);

    const updatedFile = { ...file, description: newDescription };
    const updatedFilesList = filesList.map((f) => (f.uuidName === file.uuidName ? updatedFile : f));
    setFilesList(updatedFilesList);
  };

  const getErrorText = () => {
    const exts = acceptedExtentions[calculatorName] || acceptedExtentions.COMMON;
    if (errorFilesList.length === 0) return null;
    return (
      <div className={styles.errorText}>
        The following files were not downloaded for the following reasons:
        {errorFilesList.some((file) => file.status === STATUSES.ERROR_EXTENTION) && (
          <div className={styles.tooltip}>
            Unsupported extension
            <HelpTooltip
              tooltipText={`Allowed extentions is ${[
                ...(exts.acceptedImages ?? []),
                ...(exts.acceptedDocs ?? []),
              ]?.join(', ')}`}
            />
          </div>
        )}
        {errorFilesList.some((file) => file.status === STATUSES.ERROR_SIZE) && (
          <div className={styles.tooltip}>
            File size exceeded
            <HelpTooltip tooltipText="Allowed size is 100 Mb" />
          </div>
        )}
        {errorFilesList.some((file) => file.status === STATUSES.ERROR_UPLOADING) && (
          <div>Error uploading file</div>
        )}
      </div>
    );
  };

  const renderWrongBlock = (file) => (
    // <li key={idx} title="validate" className={fileStatusStyle(file)}>
    <div className={styles.fileBlock}>
      <div className={styles.fileInfoBlock}>
        <div className={clsx(styles.rowWrapper, styles.file)}>
          <div className={clsx(styles.rowWrapper, fileStatusStyle(file.status))}>
            <TooltipState
              tooltipText={STATE_TEXT[file.status]}
              Icon={() => getFileStatusIcon(file.status)}
            />
            <div className={styles.fileName}>{file.originalName}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderFileBlock = (file) => (
    // <li key={idx} title="validate" className={fileStatusStyle(file)}>
    <div className={styles.fileBlock}>
      <div className={styles.fileInfoBlock}>
        <div className={styles.progressBarContainer}>
          <div className={clsx(styles.progressBar, fileBgStatusStyle(file.status))} />
        </div>
        <div className={clsx(styles.rowWrapper, styles.file)}>
          <div className={clsx(styles.rowWrapper, fileStatusStyle(file.status))}>
            <TooltipState
              tooltipText={STATE_TEXT[file.status]}
              Icon={() => getFileStatusIcon(file.status)}
            />
            <div className={styles.fileName}>{file.originalName}</div>
          </div>
        </div>
        <div className={styles.rowWrapper}>
          <IconButton onClick={() => onExpandClick(file.uuidName)}>
            {viewDescriptionId === file.uuidName ? (
              <ExpandLessIcon style={icoStyle} />
            ) : (
              <ExpandMoreIcon style={icoStyle} />
            )}
          </IconButton>
          <IconButton onClick={() => removeFileFromList(file)}>
            <DeleteIcon style={icoStyle} />
          </IconButton>
        </div>
      </div>
      {viewDescriptionId === file.uuidName && (
        <div className={styles.wide}>
          <TextInput
            name="description"
            label="Description"
            className={clsx(styles.questionInput, styles.wide)}
            value={file.description}
            onChange={(e) => onEditDescription(file, e.target.value)}
            multiline
            maxRows={10}
            minRows={3}
            required={false}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      <Button
        type={btnType ?? 'link'}
        startIcon={<ConfDocSvg />}
        onClick={onOpen}
        disabled={disabled}
        className={styles.docButton}
      >
        {btnText ?? 'CONFIRMATION DOC'}
      </Button>
      {isModalOpen && (
        <>
          <Modal
            open={!!fileToDelete}
            onClose={closeDeleteModal}
            onClick={(e) => e.stopPropagation()}
            title="Delete file"
            className={styles.modal}
            closeOnlyByControls
          >
            <div style={{ padding: '16px 0 20px 0' }}>
              Do you really want to delete file &quot;
              {fileToDelete?.originalName ?? ''}&quot;?
            </div>
            <div className={styles.navigation}>
              <Button type="defulat" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button type="primary" onClick={deleteQuestionsFile}>
                Delete
              </Button>
            </div>
          </Modal>
          <Modal
            open={isModalOpen}
            onClose={() => onClose(false)}
            onClick={(e) => e.stopPropagation()}
            title={title ?? 'Upload your confirmation docs'}
            className={styles.modal}
            closeOnlyByControls
            /* eslint-disable-next-line no-underscore-dangle */
            containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
          >
            <div className={styles.columnWrapper}>
              <div className={styles.rowWrapper}>
                <DropArea onDrop={updateFilesList} />
                <div className={styles.linkWrapper}>
                  <Input
                    type="file"
                    className={styles.input}
                    onChange={onInputChange}
                    multiple={isMultiple}
                    id="icon-button-file"
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      type="link"
                      component="span"
                      size="small"
                      startIcon={<FileUploadIcon />}
                    >
                      Choose the file
                    </Button>
                  </label>
                  <Button
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<DropBoxSvgG />}
                    disabled
                  >
                    UPLOAD FROM DROPBOX
                  </Button>
                  <Button
                    className={styles.disabled}
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<GdriveSvgG />}
                    disabled
                  >
                    UPLOAD FROM GOOGLE DRIVE
                  </Button>
                  <Button
                    className={styles.disabled}
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<IcloudSvgG />}
                    disabled
                  >
                    UPLOAD FROM ICLOUD
                  </Button>
                </div>
              </div>
              <div className={clsx(styles.columnWrapper, styles.wide)}>
                <>
                  <div>{getErrorText()}</div>
                  {errorFilesList.length > 0 && (
                    <ul>
                      {errorFilesList.map((file, idx) => (
                        <li key={idx} className={styles.wide}>
                          {renderWrongBlock(file, idx)}
                        </li>
                      ))}
                    </ul>
                  )}
                  {filesList.length > 0 ? (
                    <ul>
                      {filesList.map((file, idx) => (
                        <li key={idx} className={styles.wide}>
                          {renderFileBlock(file, idx)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className={styles.noFiles}>No files yet</div>
                  )}
                </>
              </div>
              <div className={styles.navigation}>
                <Button type="defulat" onClick={() => onClose(false)}>
                  Cancel
                </Button>
                {!idsForSave.length && (
                  <Button type="primary" onClick={() => onClose(false)}>
                    Ok
                  </Button>
                )}
                {!!idsForSave.length && (
                  <Button type="primary" onClick={() => onSaveFile()}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

FileUploader.propTypes = {
  questionCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  btnType: PropTypes.string,
  btnText: PropTypes.string,
  title: PropTypes.string,
  isMultiple: PropTypes.bool,
  onCloseCallback: PropTypes.func,
};

export default FileUploader;

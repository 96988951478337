/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import {
  SET_CALCULATORS_LIST,
  SET_CALCULATOR_NAME,
  SET_LINKED_RESULT
} from '../types';
import { CalculatorsUrls, CalculatorsListData } from '../../constants';

import CalculatorsContext from './calculatorsContext';
import calculatorsReducer from './calculatorsReducer';
import { setItemInStorage } from '../../utils';

const CalculatorsState = ({ children }) => {
  const initialState = {
    selectedCalculators: [],
    calculatorCost: 0,
    calculatorName: '',
    linkedResult: null,
  };
  // const [filesList, setFilesList] = useState([]);
  const [state, dispatch] = useReducer(calculatorsReducer, initialState);

  const setLinkedResult = (data) => {
    dispatch({
      type: SET_LINKED_RESULT,
      payload: data,
    });
  };

  const setCalculatorName = (name = '') => {
    if (name) setItemInStorage('SELECTED_CALCULATOR_NAME', name);
    dispatch({
      type: SET_CALCULATOR_NAME,
      payload: name,
    });
  };

  const setCalculatorsList = (calculators = []) => {
    const allCalculators = [...calculators];
    const selectedCalculators = allCalculators
      .map((name) => ({
        name,
        url: CalculatorsUrls[name],
        clearUrl: CalculatorsUrls[name].slice(0, CalculatorsUrls[name].lastIndexOf('/')),
      }))
      .filter((calculator) => calculator.url);

    dispatch({
      type: SET_CALCULATORS_LIST,
      payload: { selectedCalculators },
    });
  };

  const createCalculatorList = (name) => {
    const newCalculators = {
      ...JSON.parse(JSON.stringify(CalculatorsListData)),
    };
    if (!newCalculators[name]) return;
    const selected = newCalculators[name].children;
    setCalculatorName(name);
    setCalculatorsList(selected);
  };

  return (
    <CalculatorsContext.Provider
      value={{
        state,
        selectedCalculators: state.selectedCalculators,
        calculatorName: state.calculatorName,
        linkedResult: state.linkedResult,
        setCalculatorsList,
        createCalculatorList,
        setCalculatorName,
        setLinkedResult,
      }}
    >
      {children}
    </CalculatorsContext.Provider>
  );
};

CalculatorsState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CalculatorsState;
